import React, { Component, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { ProjectList } from '../Data';
import { toProject } from '../index';
import { TimelineMax, Quint} from "gsap";



const Slide = () => {	
	useEffect(() => {
	///////////// TEXT EFFECT /////////////
		class TextFX extends Component {
			constructor(props){
				super(props);
				this.content = document.querySelectorAll('.content__text-wrap');
				this.textsTotal = 11;
				this.middleIdx = Math.floor(this.textsTotal/2);
				this.loopInterval = {show: 80, hide: 80};
				this.loopEndIddleTime = this.loopInterval.show;
				this.nav = {
					prev: document.querySelector('.content__nav-button--prev'),
					next: document.querySelector('.content__nav-button--next')
				};
				this.current = 0
				this.slidesTotal = ProjectList.project.length;
				this.initNext();
			}
			initNext() {
				// Clicking next and previous controls.
				this.onClickPrevFnn = _ => this.navigat('prev');
				this.onClickNextFnn = _ => this.navigat('next');
				this.nav.prev.addEventListener('click', this.onClickPrevFnn);
				this.nav.next.addEventListener('click', this.onClickNextFnn);
			}

			navigat(dir){
				this.current = dir === 'next' ? 
				this.current < this.slidesTotal - 1 ? this.current + 1 : 0 :
				this.current > 0 ? this.current - 1 : this.slidesTotal - 1;	
				this.texts = this.content[this.current].querySelectorAll('.content__text');

			}
			
			show({dir = 'both', halfwayCallback = null} = {}) {
				return new Promise((resolve, reject) => {
					const loopHide = (pos) => {
						if ( this.middleIdx-pos === this.middleIdx ) {
							setTimeout(resolve, this.loopEndIddleTime);
							return;
						}
						this.hideText(pos, dir);
						setTimeout(() => loopHide(pos-1), this.loopInterval.hide);
					};
					const loopShow = (pos) => {
						if ( this.middleIdx-pos > this.middleIdx ) {
							if ( halfwayCallback && typeof halfwayCallback === 'function') {
								halfwayCallback();
							}
							loopHide(this.middleIdx);
							return;
						}
						this.showText(pos, dir);
						setTimeout(() => loopShow(pos-1), this.loopInterval.show);
					};
					loopShow(this.middleIdx);
				});
			}
			hide({dir = 'both', halfwayCallback = null} = {}) {
				return new Promise((resolve, reject) => {
					const loopHide = (pos) => {
						if ( this.middleIdx-pos < 0 ) {
							setTimeout(resolve, this.loopEndIddleTime);
							return;
						}
						this.hideText(pos, dir);
						setTimeout(() => loopHide(pos+1), this.loopInterval.hide);
					};
					const loopShow = (pos) => {
						if ( this.middleIdx-pos < 0 ) {
							if ( halfwayCallback && typeof halfwayCallback === 'function') {
								halfwayCallback();
							}
							loopHide(0);
							return;
						}
						this.showText(pos, dir);
						setTimeout(() => loopShow(pos+1), this.loopInterval.show);
					};
					loopShow(1);
				});
			}
			hideText(pos, dir) {
				this.toggleText('hide', pos, dir);
			}
			showText(pos, dir) {
				this.toggleText('show', pos, dir);
			}
			toggleText(action, pos, dir) {
				const changeStyle = {
					up: _ => {
						this.texts[this.middleIdx-pos].style.opacity = action === 'show' ? 1 : 0;
					},
					down: _ => {
						this.texts[this.middleIdx+pos].style.opacity = action === 'show' ? 1 : 0;
					}
				};
				
				if ( dir === 'both' ) {
					changeStyle['up']();
					changeStyle['down']();
				}
				else {
					changeStyle[dir]();
				}
			}
			
		}
	
		class Slider extends Component {
			constructor(props) {
				super(props)
				this.img = {
					wrap: document.querySelector('.content__img'),
					inner: document.querySelectorAll('.content__img-inner')
				};
				this.textFX = new TextFX(document.querySelector('.content__text-wrap'));				
			}
			// Hide the Slide's image
			hideImage(dir) {
				this.toggleImage('hide', dir);
			}
			// Show the Slide's image
			showImage(dir) {
				this.toggleImage('show', dir);
			}
			toggleImage(action, dir) {
				new TimelineMax().add('begin')
				.to(this.img.wrap, action === 'hide' ? 0.3 : 0.7, { 
					ease: action === 'hide' ? Quint.easeOut : Quint.easeOut,
					startAt: action === 'hide' ? {} : {x: dir === 'prev' ? '110%' : '-110%', opacity: 1},
					x: action === 'hide' ? dir === 'prev' ? '-110%' : '110%' : '0%'
				}, 'begin')
				.to(this.img.inner, action === 'hide' ? 0.3 : 0.7, { 
					ease: action === 'hide' ? Quint.easeOut : Quint.easeOut,
					startAt: action === 'hide' ? {} : {x: dir === 'prev' ? '-100%' : '100%'},
					x: action === 'hide' ? dir === 'prev' ? '100%' : '-100%' : '0%'
				}, 'begin');
			}

		}

	///////////// SLIDESHOW /////////////	
		class Slideshow extends Component {
			constructor(props){
				super(props);
				this.nav = {
					prev: document.querySelector('.content__nav-button--prev'),
					next: document.querySelector('.content__nav-button--next')
				};
				this.slides = [];
				[...document.querySelectorAll('.content__slide')].forEach(slide => this.slides.push(new Slider(slide)));
				// Total number of slides
				this.slidesTotal = this.slides.length;
				// Current slide position
				this.current = 0;
				// Show the first one
				this.slides[this.current].props.classList.add('content__slide--current');
				this.initEvents();
			}
			initEvents() {
				// Clicking next and previous controls.
				this.onClickPrevFn = _ => this.navigate('prev');
				this.onClickNextFn = _ => this.navigate('next');
				this.nav.prev.addEventListener('click', this.onClickPrevFn);
				this.nav.next.addEventListener('click', this.onClickNextFn);
			}
			navigate(dir) {
				if ( this.isAnimating ) {
					return false;
				}
				this.isAnimating = true;
				// Current slide
				const currentSlide = this.slides[this.current];
				
				// Update current
				this.current = dir === 'next' ? 
					this.current < this.slidesTotal - 1 ? this.current + 1 : 0 :
					this.current > 0 ? this.current - 1 : this.slidesTotal - 1;
				// Upcoming slide
				const upcomingSlide = this.slides[this.current];
				const onCurrentHalfwayCallback = () => {
					// Hide the current slide's image
					currentSlide.hideImage(dir);
					// Set the upcoming slide's main text opacity to 1.
					upcomingSlide.textFX.texts[upcomingSlide.textFX.middleIdx].style.opacity = 0;
					// Add current class to the upcoming slide (opacity = 1)
					upcomingSlide.props.classList.add('content__slide--current');
					// Show the upcoming slide's image
					upcomingSlide.showImage(dir);
				};
				const onCurrentEndCallback = () => {
					// Remove the current class from the current slide.
					currentSlide.props.classList.remove('content__slide--current');
					upcomingSlide.textFX.show().then(() => this.isAnimating = false);
				};
				// Hide the current slide's text, 
				// and call onCurrentHalfwayCallback at half of the animation
				// In the end call onCurrentEndCallback
				currentSlide.textFX.hide({halfwayCallback: onCurrentHalfwayCallback}).then(onCurrentEndCallback);
			}
		}
		const linksProject = document.querySelectorAll('.to-project');
  		const followtext = document.querySelector('.follower-text');

		linksProject.forEach(link => {
            link.addEventListener( 'mouseenter', () => {
                followtext.classList.add('follower-text-hover');
            })
        })
        linksProject.forEach(link => {
            link.addEventListener( 'mouseleave', () => {
                followtext.classList.remove('follower-text-hover');
            })
        })
		
		new Slideshow(document.querySelector('.content'));
	}, []);
	  	  
    return (
        <div className="content" >

			{ ProjectList.project.map((item, i) =>
				<div className="content__slide">
						<NavLink to={'/Project/'+ ProjectList.project[i].tag} className='to-project' onClick={toProject} id={ ProjectList.project[i].name }></NavLink>
						<div className="content__img">
							<div className="content__img-inner" style={{ background: `center / cover no-repeat url(${ ProjectList.project[i].img })` }}>
								<div className='overlay-img'></div>
								<div className='pagination'><span>{ i+1 } / { ProjectList.project.length }</span></div>
							</div>
						</div>
					<div className="content__text-wrap" >
						<span className="content__text">
								<span className="content__text-inner content__text-inner--stroke">{ ProjectList.project[i].name }</span>
						</span>
						<span className="content__text">
								<span className="content__text-inner">{ ProjectList.project[i].name }</span>
						</span>
						<span className="content__text content__text--full">
								<span className="content__text-inner content__text-inner--stroke">{ ProjectList.project[i].name }</span>
						</span>
						<span className="content__text">
								<span className="content__text-inner content__text-inner--stroke">{ ProjectList.project[i].name }</span>
						</span>
						<span className="content__text" >
								<span className="content__text-inner">{ ProjectList.project[i].name }</span>
						</span>
						<span className="content__text content__text--full" style={{transform: "translate3d(0,-8%,0)"}}>
								<span className="content__text-inner">{ ProjectList.project[i].name }</span>
						</span>
						<span className="content__text">
								<span className="content__text-inner content__text-inner--bottom">{ ProjectList.project[i].name }</span>
						</span>
						<span className="content__text">
								<span className="content__text-inner content__text-inner--bottom">{ ProjectList.project[i].name }</span>
						</span>
						<span className="content__text content__text--full" style={{transform: "translate3d(0,-17%,0) "}}>
								<span className="content__text-inner content__text-inner--stroke">{ ProjectList.project[i].name }</span>
						</span>
						<span className="content__text">
								<span className="content__text-inner content__text-inner--stroke content__text-inner--bottom">{ ProjectList.project[i].name }</span>
						</span>
						<span className="content__text">
								<span className="content__text-inner content__text-inner--bottom">{ ProjectList.project[i].name }</span>
						</span>
					</div>
				</div>				
			)}
			<div className="content__nav">
				<button className="content__nav-button content__nav-button--next next">next</button>
				<button className="content__nav-button content__nav-button--prev prev">prev</button>
			</div>
		</div>
		
    );

};
export default Slide;



