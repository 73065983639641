import kodo from './styles/images/kodo.png';
import kodo2 from './styles/images/kodo2.png';
import kodo3 from './styles/images/kodo3.png';
import kodo4 from './styles/images/kodo4.png';
import kodo5 from './styles/images/kodo5.png';
import kodo6 from './styles/images/kodo6.png';
import kodo7 from './styles/images/kodo7.png';
import kodo8 from './styles/images/kodo8.png';
import kodo9 from './styles/images/kodo9.png';
import kodo10 from './styles/images/kodo10.png';
import kodo11 from './styles/images/kodo11.png';
import kodo12 from './styles/images/kodo12.png';
import kodo14 from './styles/images/kodo14.png';
import kodo15 from './styles/images/kodo15.png';

import quartz from './styles/images/quartz.png';
import quartz2 from './styles/images/quartz2.png';
import quartz3 from './styles/images/quartz3.png';
import quartz4 from './styles/images/quartz4.png';
import quartz5 from './styles/images/quartz5.png';
import quartz6 from './styles/images/quartz6.png';
import quartz7 from './styles/images/quartz7.png';
import quartz8 from './styles/images/quartz8.png';
import quartz9 from './styles/images/quartz9.png';
import quartz10 from './styles/images/quartz10.png';
import quartz11 from './styles/images/quartz11.png';
import quartz12 from './styles/images/quartz12.png';
import quartz13 from './styles/images/quartz13.png';
import quartz14 from './styles/images/quartz14.png';
import quartz15 from './styles/images/quartz15.png';
import quartz16 from './styles/images/quartz16.png';
import quartz17 from './styles/images/quartz17.png';
import quartz18 from './styles/images/quartz18.png';

import topten from './styles/images/topten.jpg';
import topten2 from './styles/images/topten2.png';
import topten3 from './styles/images/topten3.png';
import topten4 from './styles/images/topten4.png';
import topten5 from './styles/images/topten5.png';
import topten6 from './styles/images/topten6.png';
import topten7 from './styles/images/topten7.png';
import topten8 from './styles/images/topten8.png';
import topten9 from './styles/images/topten9.png';
import topten10 from './styles/images/topten10.png';
import topten11 from './styles/images/topten11.png';
import topten12 from './styles/images/topten12.png';
import topten13 from './styles/images/topten13.png';
import topten14 from './styles/images/topten14.png';
import topten15 from './styles/images/topten15.png';
import topten16 from './styles/images/topten16.png';
import topten17 from './styles/images/topten17.png';
import topten18 from './styles/images/topten18.png';

import telco from './styles/images/telco.png';
import telco1 from './styles/images/telco1.png';
import telco2 from './styles/images/telco2.png';
import telco3 from './styles/images/telco3.png';
import telco4 from './styles/images/telco4.png';
import telco5 from './styles/images/telco5.png';
import telco6 from './styles/images/telco6.png';
import telco7 from './styles/images/telco7.png';
import telco8 from './styles/images/telco8.png';
import telco9 from './styles/images/telco9.png';
import telco10 from './styles/images/telco10.png';
import telco11 from './styles/images/telco11.png';
import telco12 from './styles/images/telco12.png';
import telco13 from './styles/images/telco13.png';
import telco14 from './styles/images/telco14.png';

import gds from './styles/images/gds.jpg';
import gds1 from './styles/images/gds1.png';
import gds2 from './styles/images/gds2.jpg';
import gds3 from './styles/images/gds3.jpg';
import gds4 from './styles/images/gds4.png';
import gds5 from './styles/images/gds5.png';
import gds6 from './styles/images/gds6.png';
import gds7 from './styles/images/gds7.png';
import gds8 from './styles/images/gds8.png';
import gds9 from './styles/images/gds9.png';
import gds10 from './styles/images/gds10.png';
import gds11 from './styles/images/gds11.png';
import gds12 from './styles/images/gds12.png';

import ifip from './styles/images/ifip.jpeg';
import ifip2 from './styles/images/ifip2.png';
import ifip3 from './styles/images/ifip3.png';
import ifip4 from './styles/images/ifip4.png';
import ifip5 from './styles/images/ifip5.png';
import ifip6 from './styles/images/ifip6.png';
import ifip7 from './styles/images/ifip7.png';
import ifip8 from './styles/images/ifip8.png';
import ifip9 from './styles/images/ifip9.png';
import ifip10 from './styles/images/ifip10.png';



export const ProjectList = {
    "project" : [
        {
            "name": "Saneco'Sim",
            "tag": "Sanecosim",
            "type": "UX/UI DESIGN",
            "img": ifip,
            "role": "UX/UI Designer",
            "linktitle": ["ifip.asso.fr"],
            "link": ["https://ifip.asso.fr/"],
            "apropos": ["Saneco’Sim est un outil web destiné aux éleveurs et conseillers en élevage de porcs qui permet d’estimer le coût de la mise en place de mesures de biosécurité, dans le but de les aiguiller aux prémices d’un projet en lien avec la biosécurité.","La plateforme permet d’établir facilement les coûts total et annuel des mesures de biosécurité. L’utilisation conjointe du calculateur de GT-Direct permet de relativiser l   e coût des dépenses en envisageant en parallèle une amélioration des performances techniques de l’élevage.","Au-delà de la biosécurité, Saneco’sim permet également de calculer l’impact d’un ou plusieurs traitements vétérinaires sur sa marge."],
            "contexte": ["La mise en place de mesures de biosécurité en élevage contribue à l’amélioration de la santé des animaux. Or, ces démarches sont souvent perçues comme des contraintes nécessitant des investissements importants ou des modifications de pratiques par les éleveurs. L’un des moyens de lever ces a priori est d’estimer les coûts d’investissement et /ou de fonctionnement des mesures de biosécurité.","L’Ifip a développé Saneco’Sim, un outil permettant d’évaluer le coût de mise en place de projets liés à la biosécurité.","Cet outil à destination des éleveurs et des techniciens a pour vocation de les accompagner dans la mise en oeuvre des projets de mise en place de mesures de biosécurité. Il permet d’établir facilement le coût total des mesures de biosécurité, souvent perçu comme un frein. La dépense est également calculée annuellement, de manière à être mise en relation avec la marge de l’élevage."],       
            "img2": ifip2,
            "demarche": [0, 1, 2, 3, 4],
            "recherches": [],
            "analyses": [ifip3, ifip4],
            "idea": [],
            "design": [ifip5],
            "maquettes": [ifip6, ifip7, ifip8, ifip9, ifip10],
        },

        {
            "name": "Kodo Talk",
            "tag": "Kodotalk",
            "type": "UX / UI DESIGN",
            "img": kodo,
            "role": "UX Designer",
            "linktitle": ["kodotalks.com", "instagram.com/kodotalks/"],
            "link": ["kodotalks.com", "instagram.com/kodotalks/"],
            "apropos": ["C’est le projet un peu fou de deux sœurs avides de culture et de découvertes stimulantes.", "Kôdô est une plateforme qui offre aux chercheur.ses la visibilité, la liberté de parole et  la valorisation de leurs travaux de recherche pour ainsi faire grandir et animer une communauté assoiffée de connaissances.‍", "Avec une présence sur les réseaux sociaux, Kôdô donne la parole aux chercheurs ayant à cœur de démocratiser leurs savoirs pour les rendre accessibles au plus grand nombre et valoriser leurs projets et travaux de recherche…"],
            "contexte": ["KODO est un média engagé de diffusion et de valorisation de contenus culturels, académiques et scientifiques destiné au grand public", "Il n’existe pas de site proposant une agrégation de toutes les conférences passées ou à venir de chacun des établissements français et à destination du grand public.", "Conséquence : Le grand public doit s’abonner à tout un tas de canaux différents pour recevoir l’information. Il se prive aussi de la possibilité de découvrir des sujets transverses ou complètement différents puisqu’il aura tendance à se renseigner sur les sujets de recherche proches de ses centres d'intérêt.", "Le design et l’expérience utilisateur des différentes plateformes utilisées par les établissements sont vieillissantes, pas attirantes, pas « user friendly »....", "Conséquence : difficile de rajeunir et de dépoussiérer l’image de la Recherche et d’attirer de nouveaux publics."],       
            "img2": kodo2,
            "demarche": [0, 1, 2, 3, 4],    
            "recherches": [kodo3, kodo4],
            "analyses": [kodo5, kodo6],
            "idea": [],
            "design": [kodo7, kodo8, kodo9, kodo10],
            "maquettes": [kodo11, kodo12, kodo14, kodo15],
        },
        
        {
            "name": "Quartz",
            "tag": "Quartz",
            "type": "UI DESIGN",
            "img": quartz,
            "role": "UI Designer",
            "linktitle": ["Demo Quartz"],
            "link": ["drive.google.com/file/d/1vfD1eqxUalRgrFDsRx0LvIeVetc8J_DC/view?usp=sharing"],
            "apropos": ["QUARTZ est un service de taxi autonome premium. Ce service transforme vos trajets en un moment d’évasion. Il a été conçu dans le but de profiter d’un trajet afin de libérer votre esprit et de profiter d’un moment de calme et de sérénité.", "Ce taxi premium propose un service adapté sur mesure. Grâce à son système Alive Geometry, le taxi modifie l’aspect de l’habitacle en fonction de son utilisateur, s’adapte selon les besoins avec les services que propose le véhicule. Une connectivité intelligente sera mise à disposition afin de profiter de toutes les applications compatibles, telles que Spotify, Netflix, Gmail ou autres seront automatiquement synchronisées avec la voiture.", "Les utilisateurs pourront interagir avec un hologramme pour contrôler les services dans le Taxi."],
            "contexte": ["Dans le cadre d’un projet au sein de Digital Campus, ayant pour de but de revoir et de penser la mobilité du futur, il nous a été demandé de concevoir les interfaces liées à un ou des usages au sein d’un véhicule autonome.", "En 2027, les grandes villes interdisent la circulation des véhicules diesel et essence. Les voitures thermiques ne circulent plus dans les capitales, l’urbanisation n’a jamais été aussi forte, les forêts de bitume se dessinent de plus en plus autour de vous, la pollution lumineuse devient de plus en plus agressive.", "L’apparition de taxis autonomes de niveau 5 vient comme la solution afin de se déplacer intra-muros de ces villes. Nous devions imaginer le cockpit de ce futur véhicule, ainsi que l’interface de l’application mobile permettant de commander ce taxi, mais aussi l’interface de ou des écrans embarqués permettant d’avoir toutes les informations concernant le trajet et les infos complémentaires.    "],       
            "img2": quartz2,
            "demarche": [0, 0, 2, 3, 4],
            "recherches": [],
            "analyses": [],
            "idea": [quartz3, quartz4, quartz5, quartz6],
            "design": [quartz7, quartz8, quartz9, quartz10],
            "maquettes": [quartz11, quartz12, quartz13, quartz14, quartz15, quartz16, quartz17, quartz18],
        },
      
        {
            "name": "Topten",
            "tag": "Topten",
            "type": "UX DESIGN",
            "img": topten,
            "role": "UX Designer",
            "linktitle": ["topten-indoor.fr"],
            "link": ["topten-indoor.fr"],
            "apropos": ["TOPTEN est un complexe sportif qui propose la location de terrains de foot indoor.", "Le centre offre des activités adaptées à tous, que ce soit entre amis, en famille et/ou entre collègues.", "Situé à Lagny-sur-Marne , le centre peut accueillir différents évènements tel que les tournois, anniversaires, after work ou encore enterrement pour les futurs marié.es.", "Le complexe diffuse aussi les matchs de football en live, et tout autres évènements sportifs (tennis, rugby,...) sur des écrans géants, tout en mettant à disposition un Snack/Bar pour profiter d’une ambiance exceptionnel."],
            "contexte": ["Aujourd’hui TOPTEN permet de réserver un terrain depuis son site web, mais dispose aussi d’une application mobile pour la réservation. Le centre met aussi à disposition les replays des matches afin d’analyser les actions et les buts.", "TOPTEN dispose de nombreux outils pour permettre une bonne expérience du foot indoor, hélas ces outils ne sont pas centralisés en un même point. Pour y accéder les utilisateurs sont obligés de passer par différentes plateformes.", "C’est pourquoi, il m’a été demandé au sein de Digital Campus d’améliorer cette expérience frustrante, de repenser l’expérience afin de l’optimiser et de rendre la solution plus attrayante pour les utilisateurs."],       
            "img2": topten2,
            "demarche": [0, 1, 2, 3, 4],    
            "recherches": [topten3, topten4],
            "analyses": [topten5],
            "idea": [],
            "design": [topten6, topten7],
            "maquettes": [topten8, topten9, topten10, topten11, topten12, topten13, topten14, topten18, topten15, topten16, topten17],
        },

        {
            "name": "Good Dirty Sound",
            "tag": "GoodDirtySound",
            "type": "UX / UI DESIGN & WEB DÉVELOPEMENT",
            "img": gds,
            "role": "UX Designer & Développeur",
            "linktitle": ["instagram.com/gooddirtysound"],
            "link": ["https://www.instagram.com/gooddirtysound/?hl=fr"],
            "apropos": ["Le collectif Good Dirty Sound (GDS) est une agence événementielle créée en 2011 à Sevran (93).", "Forte d’une dizaine de DJs et de 7 ans d’expérience dans l’événementiel, GDS s’est vu rassembler une communauté de plus de 7 000 personnes à Paris et sa proche banlieue.", "Fédératrice de projets innovants, GDS souhaite sublimer et faire revivre des bâtiments désaffectés à travers divers événements culturels. Des expositions, défilés de mode, clips, journées sportives ou encore soirées secrètes y ont pris vie.","Sur une réelle volonté de casser les codes pré-requis par les boîtes de nuit parisiennes et répondre à une demande accrue en événements urbains, atypiques et underground, offrant ainsi à leur communauté et leurs partenaires une expérience unique."],
            "contexte": ["Inspiré par son public et par ces followers, GDS décident de lancer une ligne de vêtements comme extension de l’univers que l’on retrouve dans leur soirées. Le style de la collection va donc être très influencé par le milieu de la nuit, l’underground et les subcultures.","Pour marquer le coup, GDS souhaitait lancer leur première collection capsule. Le collectif m'a contacté pour concevoir une plateforme de e-commerce éphémère afin de susciter la nostalgie des gens avec leurs soirées.", "Pour cette mission j'ai à la fois fait le design en allant à la rencontre des personnes mais aussi directement dans l'une de leurs fameuses soirées pour m'imprégner de l'image et de l'univers de GDS.",   "J'ai aussi eu l'occasion de développer la plateforme et de piloter la totalité du projet en collaboration avec le collectif."],       
            "img2": gds1,
            "demarche": [0, 0, 2, 3, 0],
            "recherches": [gds2, gds3],
            "analyses": [],
            "idea": [],
            "design": [gds12],
            "maquettes": [gds4, gds5, gds6, gds7, gds8, gds9, gds10, gds11],
        },
        
        {
            "name": "Tel.co",
            "tag": "Telco",
            "type": "UX DESIGN",
            "img": telco,
            "role": "UX Designer",
            "linktitle": ["botmind.io"],
            "link": ["botmind.io"],
            "apropos": ["TEL.CO est plateforme communautaire qui a pour mission de venir en aide aux agents de relation client.", "Dépassé par un travail stressant mais aussi pesant sur leur qualité de vie au travail, la plateforme met à disposition des ressources indispensables pour ainsi améliorer leurs quotidiens et gérer la pression face à une clientèle de plus en plus exigeante.", "Cette solution permet de mieux fédérer la communauté des différentes personnes travaillant dans la relation client en leur proposant une plateforme unique, en partageant du contenu, en trouvant des infos, mais qui soit aussi collaborative avec des groupes de discussions."],
            "contexte": ["Botmind est une startup qui simplifie l’usage de l’intelligence artificielle pour le support client dans l’e-commerce. Confronté à la direction support client dans les entreprises e-commerce, la startup a pour ambitions de venir en renfort des équipes de support client.","Le métier de la relation client est très “challengeant”, pour ne pas dire difficile, les équipes sont souvent débordées par les pics d’activités au cours de la journée ou au cours de l’année. Cela devient un problème pour les entreprises (pas que les e-commerçants) de fidéliser leurs employés de support client et de les garder motivés alors qu’ils font face à une forte pression au quotidien et que leur rémunération est souvent peu incitative.","C’est pourquoi Botmind souhaiterait améliorer l’apprentissage et la collaboration des agents de relation client par le biais d’une plateforme communautaire utilisant les mécanismes de gamification."],       
            "img2": telco1,
            "demarche": [0, 1, 2, 3, 4],
            "recherches": [telco2, telco3],
            "analyses": [telco4, telco5],
            "idea": [telco6, telco7],
            "design": [telco8],
            "maquettes": [telco9, telco10, telco11, telco12, telco13, telco14],
        },

    ]

}