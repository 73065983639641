import React, { useEffect } from 'react';

const Footer = () => {
    useEffect(() => {
        const stroke = document.getElementsByClassName("svg-stroke");
        const fill = document.getElementsByClassName("svg-fill");
        const link = document.getElementsByClassName("link-hover");
        
        
        link[0].addEventListener("mouseenter", (event) => {
            stroke[0].classList.add('stroke-hover');
          });
          link[0].addEventListener("mouseout", (event) => {
            stroke[0].classList.remove('stroke-hover');
          });


        link[1].addEventListener("mouseenter", (event) => {
            fill[0].classList.add('fill-hover');
          });
          link[1].addEventListener("mouseout", (event) => {
            fill[0].classList.remove('fill-hover');
          });

        link[2].addEventListener("mouseenter", (event) => {
            fill[1].classList.add('fill-hover');
          });
          link[2].addEventListener("mouseout", (event) => {
            fill[1].classList.remove('fill-hover');
          });

          link[3].addEventListener("mouseenter", (event) => {
            stroke[1].classList.add('stroke-hover');
          });
          link[3].addEventListener("mouseout", (event) => {
            stroke[1].classList.remove('stroke-hover');
          });

          const slidingTitle = document.querySelectorAll('.showup-footer');
          window.addEventListener('scroll', () => {
          
            const {scrollTop, clientHeight} = document.documentElement;
          
              // console.log(scrollTop, clientHeight);
          
            const topElementToTopViewport0 = slidingTitle[0].getBoundingClientRect().top;
            const topElementToTopViewport1 = slidingTitle[1].getBoundingClientRect().top;
                      
            if(scrollTop > (scrollTop + topElementToTopViewport0).toFixed() - clientHeight * 0.8){
                slidingTitle[0].classList.add('showup-active')
            }
            if(scrollTop > (scrollTop + topElementToTopViewport1).toFixed() - clientHeight * 0.8){
                slidingTitle[1].classList.add('showup-active')
            }
          })
    })

    return (
        <div>
            <div className='about-contact'>
                    <div className='showup-footer'>
                        <span>DISCUTONS ENSEMBLE</span>
                        <h2>N'hésitez pas à prendre contact avec moi</h2>
                    </div>
                    <div className='about-contact-link showup-footer'>
                            <a className='link-hover' href="mailto:dawan.be@gmail.com">
                            <svg className='svg-stroke' stroke="#BB001B" width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                            <g id="SVGRepo_iconCarrier"> <path d="M3 8L10.8906 13.2604C11.5624 13.7083 12.4376 13.7083 13.1094 13.2604L21 8M5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </g>
                            </svg>dawan.be@gmail.com</a>

                            <a className='link-hover' href="https://www.linkedin.com/in/dawan-be/" target="_blank" rel="noreferrer">
                            <svg className='svg-fill' width="32" height="32" viewBox="0 0 32 32" fill="#0A66C2" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.76783 11.6971H3.67383V28H8.76783V11.6971Z"/>
                            <path d="M6.11357 4C4.23522 4 3 5.2148 3 6.82773C3 8.41004 4.19439 9.65546 6.04211 9.65546H6.08294C8.00213 9.65546 9.19651 8.39983 9.1863 6.82773C9.14547 5.2148 7.99192 4 6.11357 4Z"/>
                            <path d="M22.6818 11.5441C19.7622 11.5441 17.9349 13.1366 17.598 14.2595V11.6972H11.8711C11.9426 13.0549 11.8711 28 11.8711 28H17.598V19.1902C17.598 18.69 17.5776 18.2 17.7205 17.8529C18.1084 16.8729 18.9557 15.852 20.4768 15.852C22.4674 15.852 23.3658 17.3629 23.3658 19.5679V28H29.1437V18.935C29.1437 13.892 26.3058 11.5441 22.6818 11.5441Z"/>
                            </svg>linkedin</a>

                            <a className='link-hover' href="https://drive.google.com/file/d/1iIT8MhqGWehqp-_BYsL62f4Yka_slg6J/view" target="_blank" rel="noreferrer">
                            <svg className='svg-fill' fill="#FF7900" width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 17.25c4.556 0 8.25-3.694 8.25-8.25s-3.694-8.25-8.25-8.25c-4.556 0-8.25 3.694-8.25 8.25v0c0.005 4.554 3.696 8.245 8.249 8.25h0.001zM16 3.25c3.176 0 5.75 2.574 5.75 5.75s-2.574 5.75-5.75 5.75c-3.176 0-5.75-2.574-5.75-5.75v0c0.004-3.174 2.576-5.746 5.75-5.75h0zM30.898 29.734c-1.554-6.904-7.633-11.984-14.899-11.984s-13.345 5.080-14.88 11.882l-0.019 0.102c-0.018 0.080-0.029 0.172-0.029 0.266 0 0.69 0.56 1.25 1.25 1.25 0.596 0 1.095-0.418 1.22-0.976l0.002-0.008c1.301-5.77 6.383-10.016 12.457-10.016s11.155 4.245 12.44 9.93l0.016 0.085c0.126 0.566 0.623 0.984 1.219 0.984h0c0 0 0 0 0 0 0.095 0 0.187-0.011 0.276-0.031l-0.008 0.002c0.567-0.125 0.984-0.623 0.984-1.219 0-0.095-0.011-0.187-0.031-0.276l0.002 0.008z"></path>
                            </svg>voir mon cv</a>

                            <a className='link-hover' href="https://drive.google.com/file/d/1AvXbU0Uf9X8CChefoCKxXP3IDmGl0KiZ/view" target="_blank" rel="noreferrer">
                            <svg  className='svg-stroke' width="32px" height="32px"  stroke="#B3BFFF" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5 4V17M12.5 17L7 12.2105M12.5 17L18 12.2105" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6 21H19" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>télécharger mon portfolio</a>
                    </div>
                </div>

                <div className='legal'><span>© {(new Date().getFullYear())} DAWAN BE</span></div>
        </div>
    );
};

export default Footer;