import React, { useEffect } from 'react';
import Menu from '../components/Menu';
import ProjectDetails from '../components/ProjectDetails';
import ResponsiveMenu from '../components/ResponsiveMenu';
import ScrollTop from '../components/ScrollTop';
import { cursorChange } from '../index';





const Project = () => {
    useEffect ( () => {
        cursorChange();
        var canva = document.querySelector('canvas');

            if(window.location.pathname === window.location.pathname) {
                canva.style.display="none";
            }
            else{
                canva.style.display="block";
            }

        
    }, []);


        return(
            <div>
                <Menu />
                <ProjectDetails />
                <ResponsiveMenu />
                <ScrollTop />
            </div>
        );
      
};

export default Project;