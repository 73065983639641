import React, { useEffect, useState } from 'react';

const Loader = () => {
    const [style, setStyle] = useState({ display: 'block' });
useEffect(() => {
    setTimeout(() => {
        setStyle({ display: 'none' });
    }, 1500)
}, [])

    return (
        <div className='loader' style={style}>
            <span>Chargement</span>
        </div>
    );
};

export default Loader;