import React, {  useEffect, useRef } from 'react';

const Cursor = () => {
    var cursor = useRef(null);
    var follower = useRef(null);

    var positionRef = useRef({
        mouseX: 0,
        mouseY: 0,
        destinationX: 0,
        destinationY: 0,
        distanceX: 0,
        distanceY: 0,
        key: -1,
      });
      

      useEffect(() => {
        document.addEventListener("mousemove", (event) => {
            const { clientX, clientY } = event;
      
            const mouseX = clientX;
            const mouseY = clientY;
            
            positionRef.current.mouseX =
              mouseX - follower.current.clientWidth / 2;
            positionRef.current.mouseY =
              mouseY - follower.current.clientHeight / 2;
            cursor.current.style.transform = `translate3d(${mouseX -
              cursor.current.clientWidth / 2}px, ${mouseY -
              cursor.current.clientHeight / 2}px, 0)`;
          });
      
        return () => {};
      }, []);

      React.useEffect(() => {
        const followMouse = () => {
          positionRef.current.key = requestAnimationFrame(followMouse);
          const {
            mouseX,
            mouseY,
            destinationX,
            destinationY,
            distanceX,
            distanceY,
          } = positionRef.current;

          if (!destinationX || !destinationY) {
            positionRef.current.destinationX = mouseX;
            positionRef.current.destinationY = mouseY;
          } else {
            positionRef.current.distanceX = (mouseX - destinationX) * 0.1;
            positionRef.current.distanceY = (mouseY - destinationY) * 0.1;
            if (
              Math.abs(positionRef.current.distanceX) +
                Math.abs(positionRef.current.distanceY) <
              0.1
            ) {
              positionRef.current.destinationX = mouseX;
              positionRef.current.destinationY = mouseY;
            } else {
              positionRef.current.destinationX += distanceX;
              positionRef.current.destinationY += distanceY;
            }
          }
          follower.current.style.transform = `translate3d(${destinationX}px, ${destinationY}px, 0)`;
        };
        followMouse();
      }, []);
      

/*    var posX = 0,
        posY = 0,
        mouseX = 0,
        mouseY = 0;
        useEffect(() => {
            TweenMax.to({}, 0.012, {
                repeat: -1,
                onRepeat: function() {
                    posX += (mouseX - posX) / 9;
                    posY += (mouseY - posY) / 9;
            
                    TweenMax.set(follower, {
                        css: {
                            left: posX - 15,
                            top: posY - 15
                        }
                    });
            
                    TweenMax.set(cursor, {
                        css: {
                            left: mouseX,
                            top: mouseY
                        }
                    });
                }
            })           
          }, []);

            function cursorMouve( e ) {
                mouseX = e.pageX;
                mouseY = e.pageY;
                
            }
            document.addEventListener( 'mousemove', cursorMouve, false );
 */           

    return (
        <div>
            <div className="cursor" ref={cursor}></div>
            <div className="cursor-follower" ref={follower}><div className='follower-text'>VOIR LE PROJET</div></div>
        </div>
    );
};

export default Cursor;