import React, { useEffect } from 'react';
import Menu from '../components/Menu';
import Slide from '../components/Slide';
import '../styles/css/projects.css';
import '../styles/css/slide.css';
import { cursorChange } from '../index'
import ResponsiveMenu from '../components/ResponsiveMenu';



const Projects = () => {
    useEffect ( () => {
        cursorChange();
        var canva = document.querySelector('canvas');
        if(window.location.pathname === '/Project') {
            canva.style.display="none";
        }
        else{
            canva.style.display="block";
        }
    }, []);
    
    return (
        <div>
            <Menu/>
            <div className='bgp'></div>
            <Slide />
            <ResponsiveMenu />
        </div>
    );
};


export default Projects;