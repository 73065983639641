import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toProjects } from '../index';
import { toHome } from '../index';
import { toAbout } from '../index';
import Logo from './Logo';

const ResponsiveMenu = () => {
    const [showLinks, setShowlinks] = useState(false);
    
    const handleShowLinks = () => {
        setShowlinks(!showLinks);
    }

    console.log(showLinks);

    return (
        <div className='m-header'>
            
            <div className={`m-nav ${showLinks ? "show-nav" : "hidden-nav"}`}>
            <Logo />
                <ul>
                    <NavLink to="/" onClick={toHome}>
                        <li><span>Accueil</span></li>
                    </NavLink>
                    <NavLink to="/Projects" onClick={toProjects}>
                        <li><span>Projets</span></li>
                    </NavLink>
                    <NavLink to="/About" onClick={toAbout}>
                        <li><span>À Propos</span></li>
                    </NavLink>
                </ul>
            </div>
            <button className='burger-menu' onClick={ handleShowLinks }>
                <div className={`burger-menu-content ${showLinks ? "burger-close" : "burger-normal" }`} >
                    <span className='burger-bar'></span>
                    <span className='burger-bar'></span>
                    <span className='burger-bar'></span>
                    <div className='burger-text'>
                    <p>MENU</p>
                    <p>FERMER</p>
                    </div>
                </div>
            </button> 
        </div>
    );
};

export default ResponsiveMenu;