import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/css/menu.css'
import Logo from './Logo';
import { toProjects } from '../index';
import { toHome } from '../index';
import { toAbout } from '../index';

const Menu = () => {
    return (
        <header>
            <Logo />
            <div className='navigation'>
                <ul>
                    <NavLink to="/" onClick={toHome}>
                        <li><span>Accueil</span></li>
                    </NavLink>
                    <NavLink to="/Projects" onClick={toProjects}>
                        <li><span>Projets</span></li>
                    </NavLink>
                    <NavLink to="/About" onClick={toAbout}>
                        <li><span>À Propos</span></li>
                    </NavLink>
                </ul>
            </div>
        </header>
    );
};

export default Menu;

