import React, { useEffect } from 'react';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import ScrollTop from '../components/ScrollTop';
import '../styles/css/about.css';
import Arrowd from '../styles/images/arrowd.svg';
import Me from '../styles/images/me.png';
import Research from '../styles/images/research.png';
import Ux from '../styles/images/ux.png';
import Ui from '../styles/images/ui.png';
import Dev from '../styles/images/dev.png';
import { cursorChange } from '..';
import ResponsiveMenu from '../components/ResponsiveMenu';


const About = () => {
    useEffect(() => {
        cursorChange();

        var canva = document.querySelector('canvas');
        if(window.location.pathname === '/Project') {
            canva.style.display="none";
        }
        else{
            canva.style.display="block";
        }

        const stroke = document.getElementsByClassName("svg-stroke");
        const fill = document.getElementsByClassName("svg-fill");
        const link = document.getElementsByClassName("link-hover");
        
        
        link[0].addEventListener("mouseenter", (event) => {
            stroke[0].classList.add('stroke-hover');
          });
          link[0].addEventListener("mouseout", (event) => {
            stroke[0].classList.remove('stroke-hover');
          });


        link[1].addEventListener("mouseenter", (event) => {
            fill[0].classList.add('fill-hover');
          });
          link[1].addEventListener("mouseout", (event) => {
            fill[0].classList.remove('fill-hover');
          });

        link[2].addEventListener("mouseenter", (event) => {
            fill[1].classList.add('fill-hover');
          });
          link[2].addEventListener("mouseout", (event) => {
            fill[1].classList.remove('fill-hover');
          });

          link[3].addEventListener("mouseenter", (event) => {
            stroke[1].classList.add('stroke-hover');
          });
          link[3].addEventListener("mouseout", (event) => {
            stroke[1].classList.remove('stroke-hover');
          });

          const slidingTitle = document.querySelectorAll('.showup');
          window.addEventListener('scroll', () => {
          
            const {scrollTop, clientHeight} = document.documentElement;
          
              // console.log(scrollTop, clientHeight);
          
            const topElementToTopViewport0 = slidingTitle[0].getBoundingClientRect().top;
            const topElementToTopViewport1 = slidingTitle[1].getBoundingClientRect().top;
                      
            if(scrollTop > (scrollTop + topElementToTopViewport0).toFixed() - clientHeight * 0.8){
                slidingTitle[0].classList.add('showup-active')
            }
            if(scrollTop > (scrollTop + topElementToTopViewport1).toFixed() - clientHeight * 0.8){
                slidingTitle[1].classList.add('showup-active')
            }
          })

          
    })
    const handleClickScroll = () => {
      const element = document.getElementById('about');
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };
    

    return (
        <div>
            <Menu/>
            <ResponsiveMenu />
            <ScrollTop />
            <div className='bga'></div>
            <div className='about-content'>
                <div className='about-intro'>
                    <div className='about-text'>
                        <h1>DAWAN BE</h1>
                        <span>UX/UI DESIGNER</span>
                        <p>
                        À la fois empathique et bienveillant, passionné d'innovation et par le digital. J'aime imaginer et concevoir des expériences émotionnelles. <br/>
                        Ma méthode, une démarche de conception centrée utilisateurs et des interfaces créatives.<br/>
                        Pour moi, faire de l'UX design est une façon naturelle de contribuer au
                        développement de cet univers dans lequel j’ai grandi.
                        </p>
                        <button onClick={ handleClickScroll } >Me découvrir</button>
                    </div>
                    <div className='about-photo'>
                        <img src={Me} alt="Dawan Be" />
                    </div>
                    <img src={Arrowd} alt="Scroll down" id='scrolldown' onClick={ handleClickScroll }/>
                </div>
                
                <div className='about-skills' id='about'>
                    <div className='showup'>
                        <span>DOMAINES D’EXPERTISE</span>
                        <h2>MES COMPÉTENCES</h2>
                    </div>
                    <div className='about-skills-content showup'>
                        <div className='about-skills-block'>
                            <img src={Research} alt="UX Research"/>
                            <h3>UX research</h3>
                            <p><b>Les utilisateurs au coeur de la stratégie</b><br/>
                            Recherche qualitative & quantitative, conception de personae et user journeys, élaboration de tests utilisateurs</p>
                        </div>
                        <div className='about-skills-block'>
                            <img src={Ux} alt="UX Design"/>
                            <h3>UX Design</h3>
                            <p><b> Un design qui répond à un besoin</b><br/>
                            Atelier Design Thinking, conception de nouvelles fonctionnalités, audit UX, wireframes & prototypage</p>
                        </div>
                        <div className='about-skills-block'>
                            <img src={Ui} alt="UI Design"/>
                            <h3>UI Design</h3>
                            <p><b>Un design créatif et épuré pour une expérience unique</b><br/>
                            Benchmark UI, création de maquettes graphiques, design d’interaction, design system et GUI</p>
                        </div>
                        <div className='about-skills-block'>
                            <img src={Dev} alt="Développement WEB"/>
                            <h3>Développement WEB</h3>
                            <p><b>Une architecture entre les mains d’un orfèvre</b><br/>
                            Création site web, framework JS (React, Angular), mise à jour de contenu, création & gestion de bases de données</p>
                        </div>
                    </div>
                </div>

                <Footer/>

            </div>
           
            
        </div>
    );
};

export default About;