import React, { useEffect } from 'react';
import Menu from '../components/Menu';
import Titlehome from '../components/Titlehome';

import '../styles/css/home.css'
import { NavLink } from 'react-router-dom';
import { toProjects } from '../index';
import { cursorChange } from '../index';
import ResponsiveMenu from '../components/ResponsiveMenu';


const Home = () => {
    useEffect ( () => {
        cursorChange();
        var canva = document.querySelector('canvas');
        if(window.location.pathname === '/Project') {
            canva.style.display="none";
        }
        else{
            canva.style.display="block";
        }
    }, []);
    return (
        <div>
            <Menu/>
            <Titlehome />
            <ResponsiveMenu />
            <NavLink to="/projects" className='projectLink' onClick={toProjects}>Voir les projets</NavLink>
        </div>
    );
};

export default Home;