import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Cursor from './components/Cursor';
import * as THREE from 'three';
import bg from './styles/images/bg.png';
import './styles/css/style.css';
import gsap from "gsap";
import Loader from './components/Loader';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Loader />
    <Cursor />
    <App />
  </React.StrictMode>
  
);
gsap.config({
  nullTargetWarn: false,
});


const camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 1, 1000 );
camera.position.x = 0;
camera.position.y = 0;
camera.position.z = 12;


const scene = new THREE.Scene();

const geometry = new THREE.SphereGeometry( 100, 64, 32 );
const loader = new THREE.TextureLoader();
const material = new THREE.MeshBasicMaterial({
map: loader.load(bg),
});
const mesh = new THREE.Mesh( geometry, material );
mesh.material.side = THREE.DoubleSide;
scene.add( mesh );
mesh.position.set( 0, 0, 0 );
mesh.rotation.y = 1.57;
mesh.rotation.x = 0.1;


const renderer = new THREE.WebGLRenderer();
renderer.setPixelRatio( window.devicePixelRatio );
renderer.setSize( window.innerWidth, window.innerHeight );
document.body.appendChild( renderer.domElement );
renderer.render( scene, camera );


const mouse = new THREE.Vector2();
const target = new THREE.Vector2();
const windowHalf = new THREE.Vector2( window.innerWidth / 2, window.innerHeight / 2 );

document.addEventListener( 'mousemove', onMouseMove, false );

export const toProjects = () => {
  gsap.to(camera.rotation, {
    x:0.12, y:-1.4, duration: 1,  
  });
  document.removeEventListener( 'mousemove', onMouseMove, false );
  window.scrollTo(0, 0);

}

export const toHome = () => {
  document.addEventListener( 'mousemove', onMouseMove, false );
  gsap.to(camera.rotation, {
    x:0, y:0, duration: 1,  
  });
  window.scrollTo(0, 0);
}

export const toAbout = () => {
  gsap.to(camera.rotation, {
    x:0.12, y:1.4, duration: 1,  
  });
  document.removeEventListener( 'mousemove', onMouseMove, false );
  window.scrollTo(0, 0);
}

export const toProject = () => {
  const followtext = document.querySelector('.follower-text');
  document.removeEventListener( 'mousemove', onMouseMove, false );
  window.scrollTo(0, 0);
  followtext.classList.remove('follower-text-hover');
}



export const cursorChange =() => {
  const follow = document.querySelector('.cursor-follower');
  const links = document.querySelectorAll('button, a, ul, li, #scrolldown, .backTo');
  const links2 = document.querySelectorAll('a, ul, li, #scrolldown');
  
        links.forEach(link => {
            link.addEventListener( 'mouseenter', () => {
                follow.classList.add('follower-hover');
            })
        })
        links.forEach(link => {
            link.addEventListener( 'mouseleave', () => {
                follow.classList.remove('follower-hover');
            })
        })
        links2.forEach(link => {
          link.addEventListener( 'click', () => {
              follow.classList.remove('follower-hover');
          })
      })
};

function onMouseMove( event ) {

	mouse.x = ( event.clientX - windowHalf.x );
	mouse.y = ( event.clientY - windowHalf.x );
  target.x = ( 1 - mouse.x ) * 0.0004;
  target.y = ( 1 - mouse.y ) * 0.0004;
  
  camera.rotation.x += 0.6 * ( target.y - camera.rotation.x );
  camera.rotation.y += 0.6 * ( target.x - camera.rotation.y );
}

function animate() {
  requestAnimationFrame( animate );
  renderer.render( scene, camera );
}




animate()

	

