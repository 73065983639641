import React, { useEffect } from 'react';

const ScrollTop = () => {

    useEffect(() => {
        const top = document.querySelector('.scroll-top');
        console.log(top);
        window.addEventListener('scroll', () => {
          
            const {scrollTop, clientHeight} = document.documentElement;
          
              // console.log(scrollTop, clientHeight);
          
            const topElementToTopViewport0 = top.getBoundingClientRect().top;
                
            if(scrollTop > (scrollTop + topElementToTopViewport0).toFixed() - clientHeight * 1){
                top.classList.add('showup-scroll')
            }
          })
    
    });
  
    const handleClickScrollTop = () => {
        const element = document.documentElement
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

    return (
        <button className='scroll-top'onClick={ handleClickScrollTop }>
            TOP
        </button>
    );
};

export default ScrollTop;