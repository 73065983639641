import React from 'react';

const title = () => {
    return (
        <div className='title'>  
            <svg width="613" height="77" viewBox="0 0 613 77" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.4972 57.1536V58.6536H22.9972H37.3249C43.3626 58.6536 48.5103 57.0903 52.1518 53.6162C55.806 50.1299 57.7154 44.9378 57.7154 38.163C57.7154 31.3454 55.8074 26.1507 52.1498 22.6738C48.506 19.2101 43.3571 17.6724 37.3249 17.6724H22.9972H21.4972V19.1724V57.1536ZM1.5 74.826V1.5H37.4161C52.4925 1.5 62.4936 5.49467 68.7376 11.9154C74.9901 18.3447 77.7127 27.4497 77.7127 38.163C77.7127 48.8295 74.9907 57.9358 68.7368 64.3774C62.4917 70.8098 52.4902 74.826 37.4161 74.826H1.5Z" stroke="white" strokeWidth="3"/>
            <path d="M102.693 63.8314H101.628L101.277 64.8376L97.7948 74.826H77.3897L104.919 1.5H134.965L162.581 74.826H142.088L138.605 64.8376L138.255 63.8314H137.189H102.693ZM107.208 47.8492L106.519 49.8398H108.625H131.257H133.364L132.675 47.8492L123.366 20.9534L123.017 19.944H121.949H117.933H116.865L116.516 20.9534L107.208 47.8492Z" stroke="white" strokeWidth="3"/>
            <path d="M157.175 1.5H177.355L186.622 52.2438L186.846 53.4743H188.097H190.105H191.293L191.565 52.3179L203.522 1.5H219.58L231.536 52.3179L231.809 53.4743H232.997H235.004H236.255L236.48 52.2438L245.746 1.5H265.926L250.847 74.826H222.349L214.022 35.4899L213.77 34.3005H212.555H210.547H209.331L209.079 35.4899L200.753 74.826H172.255L157.175 1.5Z" stroke="white" strokeWidth="3"/>
            <path d="M285.888 63.8314H284.823L284.472 64.8376L280.99 74.826H260.585L288.114 1.5H318.16L345.776 74.826H325.283L321.8 64.8376L321.45 63.8314H320.384H285.888ZM290.403 47.8492L289.714 49.8398H291.82H314.452H316.559L315.87 47.8492L306.561 20.9534L306.212 19.944H305.144H301.128H300.06L299.711 20.9534L290.403 47.8492Z" stroke="white" strokeWidth="3"/>
            <path d="M410.87 44.6143V1.5H430.228V74.826H406.709L377.677 30.8848L377.232 30.2116H376.426H374.418H372.918V31.7116V74.826H353.559V1.5H377.617L406.011 45.4286L406.455 46.1143H407.271H409.37H410.87V44.6143Z" stroke="white" strokeWidth="3"/>
            <path d="M525.033 36.7092V37.658L525.891 38.0645C529.567 39.8073 532.391 41.9571 534.3 44.6429C536.197 47.3121 537.262 50.6113 537.262 54.7911C537.262 60.5833 535.253 65.4851 530.583 68.9933C525.841 72.5559 518.163 74.826 506.548 74.826H463.605V1.5H507.46C518.637 1.5 525.536 3.91769 529.621 7.33002C533.662 10.7049 535.163 15.2327 535.163 19.9901C535.163 23.9343 534.149 26.72 532.554 28.8469C530.937 31.0036 528.635 32.6025 525.857 34.0076L525.033 34.4239V35.3462V36.7092ZM483.511 30.803V32.303H485.011H505.179C508.733 32.303 511.479 31.5662 513.368 30.1201C515.316 28.6296 516.17 26.5264 516.17 24.2608C516.17 22.0018 515.322 19.8634 513.39 18.3276C511.503 16.8272 508.752 16.0368 505.179 16.0368H485.011H483.511V17.5368V30.803ZM483.511 58.7892V60.2892H485.011H505.909C509.568 60.2892 512.379 59.2508 514.291 57.4986C516.205 55.7452 517.082 53.3985 517.082 51.0657C517.082 48.6802 516.197 46.4191 514.241 44.7816C512.313 43.1683 509.511 42.2966 505.909 42.2966H485.011H483.511V43.7966V58.7892Z" stroke="white" strokeWidth="3"/>
            <path d="M566.178 57.8805V59.3805H567.678H610.804V74.826H546.272V1.5H610.804V16.8546H567.678H566.178V18.3546V30.4395V31.9395H567.678H609.161V44.2956H567.678H566.178V45.7956V57.8805Z" stroke="white" strokeWidth="3"/>
            </svg>
            <span className='subTitle'>UX/UI Designer</span>
        </div>
    );
};

export default title;