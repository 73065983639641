import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import  search  from'../styles/images/search.png';
import  analys  from'../styles/images/research.png';
import  ux  from'../styles/images/idea.png';
import  ui  from'../styles/images/ui.png';
import  proto  from'../styles/images/proto.png';
import  Back  from'../styles/images/arrowback.svg';
import '../styles/css/project.css'
import Footer from '../components/Footer';
import { ProjectList } from '../Data';

const ProjectDetails = () => {
    const history = useNavigate();

    var current = "";
    if (window.location.pathname === '/Project/'+ProjectList.project[0].tag){
        current = 0;
    };
    if (window.location.pathname === '/Project/'+ProjectList.project[1].tag){
        current = 1;
    };
    if (window.location.pathname === '/Project/'+ProjectList.project[2].tag){
        current = 2;
    };
    if (window.location.pathname === '/Project/' + ProjectList.project[3].tag){
        current = 3;
    };
    if (window.location.pathname === '/Project/' + ProjectList.project[4].tag){
        current = 4;
    };
    if (window.location.pathname === '/Project/' + ProjectList.project[5].tag){
        current = 5;
    };

    useEffect(() => {
        if (current === 1){
            document.querySelector(".project-files-maquettes").classList.add("project-files-maquettes-kodo");
        };
        if (current === 3){
            document.querySelector(".project-files-maquettes").classList.add("project-files-maquettes-topten");
        };

        for (var i = 0; i < ProjectList.project[current].demarche.length; i++) {
            const block = document.querySelector('#demarche'+i);
            if(ProjectList.project[current].demarche.includes(i)){
                block.style.display = "block";
            }
        }

        const slidingTitle = document.querySelectorAll('.showup');
        window.addEventListener('scroll', () => {
          
            const {scrollTop, clientHeight} = document.documentElement;
          
              // console.log(scrollTop, clientHeight);
          
            const topElementToTopViewport0 = slidingTitle[0].getBoundingClientRect().top;
            const topElementToTopViewport1 = slidingTitle[1].getBoundingClientRect().top;
            const topElementToTopViewport2 = slidingTitle[2].getBoundingClientRect().top;
            const topElementToTopViewport3 = slidingTitle[3].getBoundingClientRect().top;

                      
            if(scrollTop > (scrollTop + topElementToTopViewport0).toFixed() - clientHeight * 0.8){
                slidingTitle[0].classList.add('showup-active')
            }
            if(scrollTop > (scrollTop + topElementToTopViewport1).toFixed() - clientHeight * 0.8){
                slidingTitle[1].classList.add('showup-active')
            }
            if(scrollTop > (scrollTop + topElementToTopViewport2).toFixed() - clientHeight * 0.8){
                slidingTitle[2].classList.add('showup-active')
            }
            if(scrollTop > (scrollTop + topElementToTopViewport3).toFixed() - clientHeight * 0.8){
                slidingTitle[3].classList.add('showup-active')
            }
            
          })
    
    });
    
    
console.log(ProjectList.project[current].recherches);
    
        
   
    return (
        <div className='project-detail-content'>                
                <div className='project-cover'>
                    <img src={  ProjectList.project[current].img } alt="project-img"/>
                    <div className='gradient-cover'></div>
                </div>
            <div className='project-content'>
                <div className='backTo' onClick={() => history(-1)}><img src={ Back } alt="Arrow-Back" /><span>Retour</span></div>
                
                <div className='project-title'>
                    <h1>{ ProjectList.project[current].name }</h1>
                    <span>{ ProjectList.project[current].type }</span>
                </div>

                <div className='project-about'>
                        <div className='project-about-text'>
                            <h3>À PROPOS</h3>
                            { ProjectList.project[current].apropos.map((item, i) => 
                                <p>{ ProjectList.project[current].apropos[i] }</p>
                            )}
                            
                        </div>
                        <div>
                            <div>
                                <h3>MON RÔLE</h3>
                                <p>{ ProjectList.project[current].role }</p>
                            </div>
                            <div className='project-about-links'>
                                <h3>LIENS UTILES</h3>
                                { ProjectList.project[current].link.map((item, i) => 
                                    <a href={ `https://${ ProjectList.project[current].link[i] }` } target="_blank" rel="noreferrer">{ ProjectList.project[current].linktitle[i] }</a>
                                )}
  
                            </div>
                        </div>
                </div>

                <div className='project-context'>
                    <div className='showup'>
                        <span>MISE EN SITUATION</span>
                        <h2>contexte</h2>
                    </div>            
                    <div className='project-context-text showup'>
                        <div>
                            { ProjectList.project[current].contexte.map((item, i) => 
                                <p>{ ProjectList.project[current].contexte[i] }</p>
                            )}
                        </div>
                        <img src={ ProjectList.project[current].img2 } alt="img-project" />
                    </div>
                </div>

                <div className='project-demarche'>
                    <div className='showup'>
                        <span>PROCESSUS MISE EN PLACE</span>
                        <h2>DÉMARCHE</h2>
                    </div>
                    <div className='project-demarche-blocks showup'>
                        <div className='about-skills-block about-skills-block-display' id='demarche0'>
                            <img src={ search } alt="Recherches"/>
                            <h3>Découverte & Recherche</h3>
                            <p>Une première phase nécessaire pour appréhender l'univers de la marque et s’assurer de répondre aux besoins et aux problématiques.</p>
                            <span>Benchmark - Sondage - Audit</span>
                        </div>
                        <div className='about-skills-block about-skills-block-display' id='demarche1'>
                            <img src={ analys } alt="Analyses"/>
                            <h3>Définition & Analyse</h3>
                            <p>On définit l’orientation du projet et les objectifs. On établit des archétypes des utilisateurs qui vont nous servir tout au long du projet.</p>
                            <span>Personae - Experience Map</span>
                        </div>
                        <div className='about-skills-block about-skills-block-display' id='demarche2'>
                            <img src={ ux } alt="Idéations"/>
                            <h3>Design & Idéation</h3>
                            <p>Une fois les informations réunies et que nous avons définit les problèmes rencontrés par les utilisateurs, il est temps de générer des idées.</p>
                            <span>Atelier d’idéation - Wireframes</span>
                        </div>
                        <div className='about-skills-block about-skills-block-display' id='demarche3'>
                            <img src={ ui } alt="Design"/>
                            <h3>Design d'interface</h3>
                            <p>L’UX design s'accompagne d'un bon design d’interface afin de donner de la couleur, du sens à nos wireframes et ainsi avoir une première approche.</p>
                            <span>Maquettes - Design System - GUI</span>
                        </div>
                        <div className='about-skills-block about-skills-block-display' id='demarche4'>
                            <img src={ proto } alt="Prototypage"/>
                            <h3>Prototypes</h3>
                            <p>Il est temps de donner vie aux idées avec la phase du prototype . Pour mieux visualiser nos maquettes et pouvoir tester notre solution.</p>
                            <span>Prototypes</span>
                        </div>
                    </div>
                    {(() => {
                        if (ProjectList.project[current].recherches.length >= 1){
                            return (
                                <div className='project-files'>
                                    <h3>Découverte & Recherche</h3>
                                    <div className='project-files-img'>
                                        { ProjectList.project[current].recherches.map((item, i) => 
                                            <img src={ ProjectList.project[current].recherches[i] } alt="project-recherches"/>
                                        )}
                                    </div>
                                </div>
                            )
                        }
                        return null;
                    })()}

                    {(() => {
                        if (ProjectList.project[current].analyses.length >= 1){
                            return (
                                <div className='project-files'>
                                    <h3>Définition & Analyse</h3>
                                    <div className='project-files-img'>
                                        { ProjectList.project[current].analyses.map((item, i) => 
                                            <img src={ ProjectList.project[current].analyses[i] } alt="project-analyses"/>
                                        )}
                                    </div>
                                </div>
                            )
                        }
                        return null;
                    })()}

                    {(() => {
                        if (ProjectList.project[current].idea.length >= 1){
                            return (
                                <div className='project-files'>
                                    <h3>Design & Idéation</h3>
                                    <div className='project-files-img'>
                                        { ProjectList.project[current].idea.map((item, i) => 
                                            <img src={ ProjectList.project[current].idea[i] } alt="project-idea"/>
                                        )}
                                    </div>
                                </div>
                            )
                        }
                        return null;
                    })()}

                    {(() => {
                        if (ProjectList.project[current].design.length >= 1){
                            return (
                                <div className='project-files'>
                                    <h3>Direction artistique</h3>
                                    <div className='project-files-img'>
                                        { ProjectList.project[current].design.map((item, i) => 
                                            <img src={ ProjectList.project[current].design[i] } alt="project-da"/>
                                        )}
                                    </div>
                                </div>
                            )
                        }
                        return null;
                    })()}

                    {(() => {
                        if (ProjectList.project[current].maquettes.length >= 1){
                            return (
                                <div className='project-files'>
                                    <h3>Maquettes</h3>
                                    <div className='project-files-maquettes'>
                                        { ProjectList.project[current].maquettes.map((item, i) => 
                                            <img src={ ProjectList.project[current].maquettes[i] } alt="project-maquettes"/>
                                        )}
                                    </div>
                                </div>
                            )
                        }
                        return null;
                    })()}
                    
                </div>
                <Footer />

            </div>
        </div>
    );
};

export default ProjectDetails;